import type { AsyncDataOptions } from "#app";

import type { ExtractFromAPI, v1 } from "@netgame/openapi";

import useAsyncFetch from "./useAsyncFetch";

type Response = Required<ExtractFromAPI<v1.paths, "/rest/quest/", "get">>;
type DayInfo = NonNullable<Response["data"]>["dayInfo"];
type Tasks = NonNullable<NonNullable<DayInfo>["tasks"]>;

const removeGamesFromTasks = (tasks: Tasks) =>
	tasks.map((task) => {
		if (task.action?.type === "gamesList") {
			return {
				...task,
				action: {
					...task.action,
					details: {
						...task.action.details,
						games: task.action.details?.games?.map(({ id }) => id)
					}
				}
			};
		}
		return task;
	});

const useQuestData = (options?: AsyncDataOptions<Response> & { cached?: true }) => {
	const asyncData = useAsyncFetch({
		path: "/rest/quest/",
		method: "get",
		options: {
			cached: options?.cached ?? true,
			server: options?.server,
			immediate: options?.immediate,
			watch: options?.watch,
			transform: (data) => ({
				...data.data,
				dayInfo: {
					...data.data?.dayInfo,
					tasks: removeGamesFromTasks(data.data?.dayInfo?.tasks || [])
				}
			})
		}
	});

	const isSeasonQuest = computed(() => asyncData.data.value.questInfo?.type === "season");
	const isAdventQuest = computed(() => asyncData.data.value?.questInfo?.type === "advent");
	const isFreeSpinQuest = computed(() => asyncData.data.value?.questInfo?.type === "fp_challenge");

	return {
		...asyncData,
		isSeasonQuest,
		isFreeSpinQuest,
		isAdventQuest
	};
};

export default useQuestData;
